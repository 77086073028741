<template>
  <div>
    <header-menu ref="HeaderMenu"></header-menu>
    <div class="view-content">
      <div class="banner" id="about_0">
        <div class="desc__box text_left">
          <h1 class="banner_title b_b_space font-size_60">关于正也科技</h1>
          <p class="banner_desc b_b_space font-size_20">
            十年砥砺携手前行 加持药企营销智能
          </p>
        </div>
      </div>
      <h2 class="header_title b_b_space font-size_40" id="about_1">公司介绍</h2>
      <div class="l_r_space t__space medicineManagement_1">
        <a-row type="flex" style="align-items: center" :gutter="100">
          <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
            <img
              src="@/assets/img/png/company_jieshao.png"
              alt="药企信息化解决方案"
              style="max-width: 100%"
            />
          </a-col>
          <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
            <div class="text_left">
              <p class="col_p_text font-size_20">
                正也科技（上海正也信息科技有限公司）是医药企业营销的智能化和数智化转型服务商，是高新技术企业。
              </p>
              <p class="col_p_text font-size_20">
                正也S2P是针对医药企业的营销智能管理平台，系列产品有：主数据管理平台、辖区管理系统、销讯通·行为管理、彩蝶·流向管理、芒哥·合规管理、虎珀·费用管理、财猫·订单管理。
              </p>
              <p class="col_p_text font-size_20">
                正也科技已经服务了太极集团、康恩贝、振东制药、青岛百洋、西南制药、国药集团、黄海制药、昆药集团、海南快克等知名药企。
              </p>
              <!-- <p class="col_p_text font-size_20">
                同时，我们自主开发企业管理的多屏合一的销售终端信息系统。立足于移动端与PC端的数据交互，从而实现人员管理、销售管理、拜访管理、绩效管理、知识考核、活动管理、经验分享、信息发布的图表化呈现与系统化管理。
              </p>
              <p class="col_p_text font-size_20">
                我们为众多国内企业提供专业化的系统搭建服务。我们不仅仅专注程序开发，我们更重视项目研究；我们不仅仅关注客户需求分析，更重视用户实际体验。
              </p>
              <p class="col_p_text font-size_20">
                我们的客户涉及了医药、建材、金融、通讯运营、B2C网络营销等领域。
              </p> -->
            </div>
          </a-col>
        </a-row>
      </div>
      <div
        class="l_r_space t__space bgc_f4fbff medicineManagement_2"
        id="about_2"
      >
        <h2 class="nav__title">管理团队</h2>
        <div class="administrator">
          <a-row
            type="flex"
            style="align-items: center; padding-bottom: 0.8rem"
            v-for="(item, index) in administrator"
            :key="index"
          >
            <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" :xxl="12">
              <img
                class="administrator_avatar_1"
                :src="item.img_url"
                :alt="item.name"
              />
              <div class="avatar_name font-size_40">{{ item.name }}</div>
              <div class="avatar_company font-size_26">{{ item.job }}</div>
            </a-col>
            <a-col
              :xs="24"
              :sm="24"
              :md="12"
              :lg="12"
              :xl="12"
              :xxl="12"
              class="text_left"
            >
              <p
                class="font-size_20"
                style="max-width: 778px; color: #000"
                v-for="(item1, index1) in item.desc"
                :key="index1"
              >
                {{ item1 }}
              </p>
            </a-col>
          </a-row>
        </div>
      </div>

      <div
        class="l_r_space t__space"
        style="padding-bottom: 0.8rem; background: #f0f0f0f"
        id="about_3"
      >
        <h2 class="nav__title">资质荣誉</h2>
        <div style="padding-top: 0.6rem">
          <a-row type="flex" style="align-items: center" :gutter="20">
            <a-col
              :xs="12"
              :sm="12"
              :md="8"
              :lg="8"
              :xl="8"
              :xxl="8"
              v-for="(item, index) in honorList"
              :key="index"
            >
              <a-row
                style="align-items: center; min-height: 2rem"
                type="flex"
                class="honor_item font-size_20"
              >
                <a-col span="10">
                  <img
                    :src="item.img_url"
                    :alt="item.title"
                    :style="{
                      width: `${item.width / 100}rem`,
                      minWidth: '30px',
                    }"
                  />
                </a-col>
                <a-col span="14" class="text_left">{{ item.title }}</a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import HeaderMenu from "@/components/HeaderMenu";
import aboutUs_1 from "@/assets/img/png/aboutUs_1.png";
import aboutUs_2 from "@/assets/img/png/aboutUs_2.png";
import honor_1 from "@/assets/img/png/honor_1.png";
import honor_2 from "@/assets/img/png/honor_2.png";
import honor_3 from "@/assets/img/png/honor_3.png";
import honor_4 from "@/assets/img/png/honor_4.png";
import honor_5 from "@/assets/img/png/honor_5.png";
export default {
  name: "aboutUs",
  beforeCreate() {
    this.anchor_type = this.$route.params.anchor_type || 0;
    console.log("this.anchor_type", this.anchor_type);
  },
  components: { Footer, HeaderMenu },
  data() {
    return {
      honorList: [
        {
          title: "高新技术企业",
          img_url: honor_1,
          width: 106,
          height: 77,
        },
        {
          title: "2019年中国杰出医药服务商金石奖",
          img_url: honor_2,
          width: 103,
          height: 120,
        },
        {
          title: "通过ISO27001信息安全管理体系认证",
          img_url: honor_3,
          width: 111,
          height: 126,
        },
        {
          title: "2018绩效优化与人才发展大会第一名",
          img_url: honor_4,
          width: 96,
          height: 108,
        },
        {
          title: "2015年上海市科委技术创新资金",
          img_url: honor_5,
          width: 82,
          height: 108,
        },
      ],
      administrator: [
        {
          name: "黄诚",
          job: "正也科技CEO",
          img_url: aboutUs_1,
          desc: [
            "《医药行业大趋势》主编 ，中国医疗保健国际交流促进会循证分会委员，中国食品药品企业质量安全促进会追溯专业委员会委员，中国中医药信息研究会临床研究分会理事，河南大学医药产业数字化发展研究中心主任。",
            "专注于OTC和处方药的市场规划与推广架构的顶层设计与产品概念的建设，以及企业转型过程中的多渠道多模式增长策略。近年来，对绩效管理、市场数据挖掘、企业数据平台建设进行了体系性与个性化研究，同时创立了“S2P医药营销数字化管理平台”。",
          ],
        },
        {
          name: "王孝顺",
          job: "正也科技总经理",
          img_url: aboutUs_2,
          desc: [
            "10年互联网研发管理经验（6年腾讯工作）；7年中国医药行业营销管理平台践行者；专注于医药营销绩效管理、营销数据分析BI呈现；创立“S2P销讯通”医药营销数字化管理平台，帮助70多家中大型制药企业完成医药营销数字化转型",
          ],
        },
      ],
    };
  },
  mounted() {},
  created() {
    setTimeout(() => {
      this.goAnchor(`#about_${this.anchor_type}`);
    }, 200);
  },
  methods: {
    goAnchor(selector) {
      document.documentElement.style["scroll-behavior"] = "smooth";
      document.documentElement.scrollTop = document.body.scrollTop =
        this.$el.querySelector(selector).offsetTop - 80;
      document.documentElement.style["scroll-behavior"] = "";
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/style/color.scss";
.honor_content {
  color: $color-b3b3b3;
}
.right-circle {
  width: 0.28rem;
  height: 0.28rem;
  display: inline-block;
  margin-left: 0.16rem;
  vertical-align: middle;
}
.header_title {
  padding: 0.5rem 0;
  color: $color-darkBlue;
}
.banner {
  width: 100%;
  position: relative;
  background: url("~@/assets/img/png/aboutUs_banner.png");
  background-size: 100% 100%;
  height: 8rem;
  overflow: hidden;
  .desc__box {
    position: absolute;
    top: 2.2rem;
    left: 13.5%;
    .banner_title {
      color: $color-white;
    }
    .banner_desc {
      width: 5.75rem;
      padding-top: 0.45rem;
      color: $color-white;
    }
  }
}
.honor_item {
  background-color: $color-white;
  margin: 0.1rem 0;
  height: 100%;
  padding: 0.2rem;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: #eee 0px 0px 10px;
  min-height: 2rem;
}
.medicineManagement_1 {
  padding-bottom: 1rem;
}
.medicineManagement_2 {
  padding-bottom: 0.3rem;
}
.medicineManagement_3 {
  padding-top: 1.2rem;
  padding-bottom: 1.4rem;
}
.administrator {
  padding-top: 0.5rem;
  .administrator_avatar_1 {
    margin: 0 auto;
    width: 50%;
    max-width: 208px;
  }
  .avatar_name {
    color: $color-lightBlue;
  }
  .avatar_company {
    color: $color-darkBlue;
  }
}
.col_p_text {
  max-width: 772px;
  color: $color-black;
}
@media all and (max-width: 768px) {
  .administrator {
    .administrator_avatar_1 {
      height: 120px;
      width: 120px;
    }
  }
}
@media all and (max-width: 576px) {
  .administrator {
    .administrator_avatar_1 {
      width: 100px;
      height: 100px;
    }
  }
}
</style>
